import { useCallback, useEffect, useRef } from 'react'

import { RuianDetailResponse } from '../../common/ruianTypes'
import { useAppSelector } from '../../redux/hooks'
import { isRuianArea, isRuianBuilding, isRuianUnit } from './ruianHelpers'
import { useFetchPropertyCrmDrm } from './useFetchPropertyCrmDrm'

export interface UseCadastralWindowResult {
  openWindow: () => void
  isOpen: boolean
}

export const useCadastralWindow = (
  ruianData: RuianDetailResponse | null,
  autoImport?: boolean,
  autoOpen?: boolean,
  onClose?: () => void
): UseCadastralWindowResult => {
  const crmId = useAppSelector(state => state.myProfile.regestaCrm)
  const windowRef = useRef<Window | null>(null)
  const intervalRef = useRef<number>()
  const beenOpenedRef = useRef(false)
  const fetchCrmDrm = useFetchPropertyCrmDrm()

  const tryFetchData = useCallback(async () => {
    if (!ruianData) return
    await fetchCrmDrm(ruianData.id || '', ruianData.type)
  }, [fetchCrmDrm, ruianData])

  const checkWindowStatus = useCallback(async () => {
    console.log('Checking window status', windowRef.current)
    if (windowRef.current?.closed) {
      windowRef.current = null
      onClose?.()
      await tryFetchData()
    }
    if (!windowRef.current) {
      clearInterval(intervalRef.current)
    }
  }, [onClose, tryFetchData])

  const sendMessageToWindow = useCallback(
    (window: Window) => {
      if (!ruianData || !crmId) return

      setTimeout(() => {
        window.postMessage(
          'regestaCrm:' + crmId,
          'https://nahlizenidokn.cuzk.gov.cz'
        )
      }, 1500)
    },
    [crmId, ruianData]
  )

  const openWindow = useCallback(() => {
    if (!ruianData) return

    const url = new URL('https://nahlizenidokn.cuzk.gov.cz/ZobrazObjekt.aspx')
    if (!autoImport) {
      url.searchParams.set('rr', 'true')
    }

    if (isRuianBuilding(ruianData)) {
      url.searchParams.set('typ', 'budova')
      url.searchParams.set('id', `${ruianData.isknBudovaId}`)
    } else if (isRuianArea(ruianData)) {
      url.searchParams.set('typ', 'parcela')
      url.searchParams.set('id', `${ruianData.kod}`)
    } else if (isRuianUnit(ruianData)) {
      url.searchParams.set('typ', 'budova')
      url.searchParams.set('id', `${ruianData.vBudove?.isknBudovaId}`)
      url.searchParams.set('otevritJednotku', `${ruianData.cisloJednotky}`)
    }

    const drawerWidth = Math.min(500, window.innerWidth * 0.75)
    const leftPosition = window.innerWidth - drawerWidth - 800

    windowRef.current = window.open(
      url,
      'oknonahlizeni',
      `width=800,height=${window.outerHeight},left=${leftPosition},top=0`
    )

    if (windowRef.current) {
      intervalRef.current = window.setInterval(checkWindowStatus, 500)
      sendMessageToWindow(windowRef.current)
    }
    return
  }, [ruianData, checkWindowStatus, autoImport, sendMessageToWindow])

  useEffect(() => {
    if (autoOpen && ruianData && !beenOpenedRef.current) {
      beenOpenedRef.current = true
      openWindow()
    }
  }, [autoOpen, ruianData, openWindow])

  return {
    openWindow,
    isOpen: !!windowRef.current,
  }
}
